import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Customer = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const params = useParams();
    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        async function getData() {
            const response = await fetch('api/customers/'+ params.id)
            .then((res) => res.json())
            .then((json) => {
                setdata(json);
                setloading(false)
            });
        }
        getData();
    }, []);

    function renderCustomer(data) {
        return (
            <form>
                <h1>{data.name}</h1>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Dashboard" {...a11yProps(0)} />
                        <Tab label="Projects" {...a11yProps(1)} />
                        <Tab label="General" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                {data.comments.map(comment =>
                                    <div>{comment.comment}</div>
                                )}
                                </div>
                                <div class="col-sm">
                                {data.tasks.map(task =>
                                    <div>{task.comment}</div>
                                )}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.projects.map(project =>
                                            <tr>
                                                <td>{project.id}</td>
                                                <td>{project.name}</td>
                                                <td>{project.created}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                    <div class="form-group">
                                        <lable for="inputCompanyName">ID</lable>
                                        <input type="text" disabled class="form-control" id="inputCompanyName" value={data.id}/>
                                    </div>
                                    <div class="form-group">
                                        <lable for="inputCompanyName">Company name</lable>
                                        <input type="text" class="form-control" id="inputCompanyName" value={data.name}/>
                                    </div>
                                    <div class="form-group">
                                        <lable for="inputCompanyName">Phone</lable>
                                        <input type="text" class="form-control" id="inputCompanyName" value={data.phone}/>
                                    </div>
                                    <div class="form-group">
                                        <lable for="inputCompanyName">Address</lable>
                                        <input type="text" class="form-control" id="inputCompanyName" value={data.shippingAddress}/>
                                    </div>
                                    <div class="form-group">
                                        <lable for="inputCompanyName">City</lable>
                                        <input type="text" class="form-control" id="inputCompanyName" value={data.shippingCity}/>
                                    </div>
                                    <div class="form-group">
                                        <lable for="inputCompanyName">Vat</lable>
                                        <input type="text" class="form-control" id="inputCompanyName" value={data.vat}/>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>             
                </Box>
            </form>
        );
    }

    let content = loading
        ? <CircularProgress />
        : renderCustomer(data)

    return (
        <>
        {content}
        </>
    );
}
