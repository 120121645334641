import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CircularProgress, Button } from '@mui/material';
import Moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { SelectableTaskList } from './SelectableTaskList';
import "react-sliding-pane/dist/react-sliding-pane.css";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Timeregister } from './Timeregister';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoResult from './controls/NoResult';
import UseProfile from './controls/UseProfile';
import Box from '@mui/material/Box';
import axios from 'axios';
import { FabTimer } from './controls/FabTimer';

export const Dashboard = (props) => {
    const { profile, setProfile } = UseProfile();
    const history = useHistory();
    const params = useParams();
    const [data, setdata] = useState([]);
    const [date, setDate] = useState();
    const [projects, setProjects] = useState([]);
    const [snackbarMsg, setSnackbarMsg] = useState();
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [loading, setloading] = useState(true);
    const [loadingTimeRegs, setloadingTimeRegs] = useState(true);
    const [currentReg, setCurrentReg] = useState(null);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        isPaneOpen: false,
        isRegPaneOpen: false
    });

    useEffect(() => {
        props.checkAuth();
        let currentDate = Moment().format("YYYY-MM-DD");
        if(params.date) {
            currentDate = Moment(params.date).format("YYYY-MM-DD");
        }
        registerLocale('sv', sv)
        setDefaultLocale("sv");
        setDate(currentDate);
        getData(currentDate);
        getProjects();
    }, [params]);

    const handleSuccessClose = () => {
        setSuccessOpen(false);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };
   
    const onSave = (res) => {
        setSnackbarMsg(res.msg);
        setSuccessOpen(res.error === false);
        setErrorOpen(res.error === true);
        if(res.error === false) {
            getData(date);
            setState({ isRegPaneOpen: false });
        }
    }

    const onTimeregBack = () => {
        setState({ ...state, ["bottom"]: false });
    }

    const onBack = () => {
        props.checkAuth();
        setState({ ...state, ["right"]: false });
    }

    const fabStyle = {
        position: 'fixed',
        bottom: 30,
        right: 20,
    };

    async function getData(date) {
        setloadingTimeRegs(true);
        await axios.get(`api/dashboard/${date}`, { headers: { "Authorization": `Bearer ${profile.token}` } })
        .then(res => {
            setdata(res.data);
            setloadingTimeRegs(false);
            setloading(false);
        })
        .catch(error => {
            console.log(error);
        });
    }

    async function getProjects() {
        await axios.get(`api/timereg/activeprojects`, { headers: { "Authorization": `Bearer ${profile.token}` } })
            .then(res => {
                setProjects(res.data);
            })
            .catch(error => {
            });
    }

    function handleDateChange(step) {
        history.push(`/Dashboard/${Moment(date).add(step, "days").format("YYYY-MM-DD")}`);
    }
    
    const toggleDrawer = (anchor, open, reg) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.checkAuth();
        setCurrentReg(reg);
        setState({ ...state, [anchor]: open });
    };

    const openTimerReg = (event, reg) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.checkAuth();
        setCurrentReg(reg);
        setState({ ...state, ["bottom"]: true });
    }

    function renderTimeregs(data) {
        if(data.timeregs.length > 0) {
            return(
                <>
                    <Box
                        sx={{
                            width: "100%",
                            padding: 1,
                            paddingBottom: 0,
                            marginTop: 1,
                            color: 'white',
                            backgroundColor: 'primary.dark',

                        }}
                    >
                        <Typography color={"secondary"} fontWeight="bold">Mina registreringar</Typography>
                    </Box>
                    <table className='table'>
                        <tbody>
                        {data.timeregs.map((reg) => (
                            <tr key={reg.id} className="border-dark" onClick={toggleDrawer("bottom", true, reg)}>
                                <td>
                                    <div className='d-inline-block text-truncate' style={{width: "250px"}}>
                                        <Typography color={"primary"} fontSize="1rem">{reg.text}</Typography>
                                        <Typography color={"secondary"} fontSize=".8rem">{reg.customerName} - {reg.projectName}</Typography>
                                    </div>
                                </td>
                                <td><Typography color={"secondary"} fontSize="1rem">{reg.debWork} h</Typography></td>
                            </tr>
                        ))}

                        </tbody>
                        <tfoot>
                            <tr>
                                <td><Typography color={"secondary"} fontWeight="bold">Summa</Typography></td>
                                <td><Typography color={"secondary"} fontWeight="bold">{data.timeregSum} h</Typography></td>
                            </tr>
                        </tfoot>
                    </table>
                </>
            )
        }
        else {
            return (
                <NoResult>Inga tider registrerade</NoResult>
            )
        }
    }


    function renderDashboard(data) {
        var options = { weekday: "long", month: "short", day: "numeric" }; 
        return (
            <div>
                <div className="row mt-2">
                    <div className="col text-left" style={{display: "flex"}}>
                        <IconButton onClick={() => handleDateChange(-1)} size="large">
                            <ArrowBackIosIcon fontSize='inherit' />
                        </IconButton>
                    </div>
                    <div className="col-6 text-center align-middle" style={{display: "flex"}}>
                        <Button size="small" startIcon={<CalendarTodayIcon />}>
                            <DatePicker dateFormat="eeee d MMM" className="datePicker" selected={Date.parse(date)} onChange={(dte) => history.push(`/Dashboard/${Moment(dte).format("YYYY-MM-DD")}`)} />
                        </Button>
                    </div>
                    <div className="col" style={{display: "flex"}}>
                        <IconButton style={{marginLeft: "auto"}} onClick={() => handleDateChange(1)} size="large">
                            <ArrowForwardIosIcon fontSize='inherit' />
                        </IconButton>
                    </div>
                </div>
                <div className='mt-2' style={{marginBottom: "100px"}}>
                    {timeregs}
                </div>

                <Fab 
                    sx={fabStyle} 
                    aria-label="Lägg till" 
                    color={'primary'}
                    aria-controls={undefined}
                    aria-haspopup="true"
                    aria-expanded={undefined}
                    onClick={toggleDrawer("right", true)}
                ><AddIcon/></Fab>
                <FabTimer timer={data.timer} onClick={openTimerReg} />

                <Drawer
                    anchor={"right"}
                    open={state["right"]}
                    PaperProps={{
                        sx: { width: "100%" },
                      }}
                    onClose={toggleDrawer("right", false)}
                    >
                    <SelectableTaskList date={date} projects={projects} tasks={data.assignedTasks} onSave={onSave} onBack={onBack} />
                </Drawer>

                <Drawer
                    anchor={"bottom"}
                    open={state["bottom"]}
                    onClose={toggleDrawer("bottom", false)}
                    sx={{backgroundColor:"#212529"}}
                    >
                    <Timeregister projects={projects} currentReg={currentReg} onSave={onSave} onBack={onTimeregBack} />
                </Drawer>

            </div>
        );
    }

    let timeregs = loadingTimeRegs
        ? <CircularProgress className='centered' />
        : renderTimeregs(data)

    let content = loading
        ? <CircularProgress className='centered' />
        : renderDashboard(data)

    return (
        <>
        {content}
        <Snackbar open={successOpen} autoHideDuration={4000} onClose={handleSuccessClose}>
            <MuiAlert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
            {snackbarMsg}
            </MuiAlert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={4000} onClose={handleErrorClose}>
            <MuiAlert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            {snackbarMsg}
            </MuiAlert>
        </Snackbar>
        </>
    );
}
