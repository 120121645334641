import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export const HourPicker = (props) => {
    const [tmpValue, setTmpValue] = useState("1h");
    const [value, setValue] = useState(1);

    useEffect(() => {
        let val = props.value;
        setControls(val);
    }, [props]);

    const setControls = (val) => {
        setValue(val);
        setTmpValue(`${val}`);
    }

    const handleClick = (step) => {
        let val = value + step;
        if(val >= 0) {
            setControls(val);
            props.onChange(val);
        }
    }

    return (
        <>
            <ArrowCircleLeftIcon sx={{fontSize: "3rem"}} onClick={() => handleClick(-1)} />
            <TextField size='small' 
                label="tim"
                disabled
                inputProps={{
                    style: {fontSize: 20}
                }} 
                sx={{width: "50px"}} value={tmpValue}></TextField>
            <ArrowCircleRightIcon sx={{fontSize: "3rem"}} onClick={() => handleClick(1)} />
        </>
    );
}