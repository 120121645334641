

function getMinDiff(startDate, endDate) {
    const msInMinute = 60 * 1000;
    return Math.round(
        Math.abs(endDate.getTime() - startDate.getTime()) / msInMinute
    );
}

export const ElapsedMinutes = (timer) => {
    let startdate = new Date();
    let enddate = new Date(timer.created);
    let minDiff = getMinDiff(startdate, enddate);
    return minDiff;
}
