import React from 'react';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Dashboard } from './components/Dashboard';
import { FetchData } from './components/FetchData';
import { FetchEmployees } from './components/FetchEmployees';
import { FetchCustomers } from './components/FetchCustomers';
import { Customer } from './components/Customer';
import { Counter } from './components/Counter';
import { FetchCalendar } from './components/FetchCalendar';
import { Login } from './components/Login';
import { Debuglogin } from './components/Debuglogin';
import { Logout } from './components/Logout';
import { Campaigns } from './components/Campaigns';
import { Contacts } from './components/Contacts';
import UseProfile from './components/controls/UseProfile';

import './custom.css'

export const App = () => {
    const { profile, setProfile, verifyToken } = UseProfile();
    const history = useHistory();

  const onLogout = () => {
    logout();
  }

  const logout = () => {
    setProfile(null);
    history.push("/login");
  }

  const checkAuth = () => {
    if(!verifyToken()) {
      logout();
    }
  }

  if(!verifyToken()) {
    return (
    <Layout>
      <Login setProfile={setProfile} />
    </Layout>
    )
  }

  return (
    <Layout onLogout={onLogout}>
      <Route exact path='/' component={() => <Dashboard checkAuth={checkAuth} onLogout={onLogout}/>} />
      <Route path='/Dashboard/:date?' component={() => <Dashboard checkAuth={checkAuth} onLogout={onLogout}/>} />
      <Route path='/counter' component={Counter} />
      <Route path='/fetch-data' component={FetchData} />
      <Route path='/campaigns' component={ () => <Campaigns checkAuth={checkAuth} onLogout={onLogout}/>} />
      <Route path='/employees' component={FetchEmployees} />
      <Route path='/calendar' component={FetchCalendar} />
      <Route path='/logout' component={Logout} />
      <Route path='/login' component={() => <Login setProfile={setProfile} />} />
      <Route path='/debuglogin' component={() => <Debuglogin setProfile={setProfile} />} />
      <Route path='/customers' component={FetchCustomers} />
      <Route path='/contacts' component={Contacts} />
      <Route path='/customer/:id' component={Customer} />
    </Layout>
  )
}
