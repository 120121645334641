import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const CampaignDetails = (props) => {
    const [campaign, setCampaign] = useState();

    useEffect(() => {
        setCampaign(props.campaign);
    }, [props]);

    const onBack = () => {
        props.onBack();
    }

    return (
        <Box
            sx={{
                width: "100%",
                padding: 2,
                color: 'white',
                backgroundColor: 'primary.dark'
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    padding: 1,
                    color: 'white',
                    backgroundColor: 'primary.dark'
                }}
            >
                <div className="row mt-1 mb-1">
                    <div className="col text-left" style={{display: "flex", alignItems: "center"}}>
                        <ArrowBackIosIcon fontSize='medium' color="white" onClick={onBack}/>
                    </div>
                    <div className="col-10 text-center" style={{display: "flex", alignItems: "center", textAlign: "center"}}>
                        <Typography fontSize="1.2rem">&nbsp;</Typography>
                    </div>
                </div>
            </Box>
            {campaign &&
                <div className='zoom' dangerouslySetInnerHTML={{ __html: campaign.html }} />
            }
        </Box>
    )
}