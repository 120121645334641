import { useState } from 'react';

export default function UserProfile() {

  const getProfile = () => {
    const tokenString = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    return {
        token: JSON.parse(tokenString),
        user: JSON.parse(userString)
    }
  };

  const [profile, setProfile] = useState(getProfile());

  const saveProfile = userProfile => {
    if(userProfile) {
        localStorage.setItem('token', JSON.stringify(userProfile.token));
        localStorage.setItem('user', JSON.stringify(userProfile.user));
        setProfile(userProfile);
    }
    else {
        localStorage.clear();
    }
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const verifyToken = () => {
    if(profile?.token) {
        const decodedJwt = parseJwt(profile.token);
        if (decodedJwt.exp * 1000 > Date.now()) {
            return true;
        }
    }
    return false;
  };

  return {
    verifyToken: verifyToken,
    setProfile: saveProfile,
    profile
  }
}