import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CircularProgress, Box } from '@mui/material';
import axios from 'axios';

export const Login = (props) => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [firstStep, setFirstStep] = useState(true);
    const [errorOpen, setErrorOpen] = useState(false);
    const [progress, setProgress] = useState('none');
    const [errors, setErrors] = useState([]);
    const refMail = useRef(null);
    const refCode = useRef(null);
    const history = useHistory();

    async function loginUser(credentials) {
        await axios.post("api/security/authenticate", credentials)
            .then(res => {
                props.setProfile(res.data);
                history.push("/");
            })
            .catch(error => {
                setErrors([error.response.data.message]);
                setErrorOpen(true);
            });
    }

    async function generateAuthCode(credentials) {
        await axios.post("api/security/authcoderequest", credentials)
            .then(res => {
                setFirstStep(false);
                refCode.current.focus();
            })
            .catch(error => {
                setErrors([error.response.data.message]);
                setErrorOpen(true);
            });
    }

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    const back = () => {
        setFirstStep(true);
    };

    const handleSubmit = async e => {
        setProgress('display');
        e.preventDefault();

        let credentials = {
            userName,
            password
        };

        if(firstStep) {
            await generateAuthCode(credentials);
        }
        else {
            await loginUser(credentials);
        }

        setProgress('none');
    }

    function step1() {
        return (
            <>
                <div className='mt-5'>
                    <Typography>Skriv in din e-postadress så kommer en engångskod att skickas till dig via SMS.</Typography>
                </div>
                <div className='mt-3'>
                    <TextField ref={refMail} label="E-post" type="email" autoFocus fullWidth value={userName} onChange={e => setUserName(e.target.value)}></TextField>
                </div>
                <div className='mt-3'>
                    <Button variant='contained' color='primary' fullWidth type='submit' endIcon={<NavigateNextIcon/>}>Nästa</Button>
                </div>
            </>
        );
    }

    function step2() {
        return (
            <>
                <div className='mt-5'>
                    <Typography>Ange den 4-siffriga kod som du fått via SMS. Koden är endast giltig i 5 min.</Typography>
                </div>
                <div className='mt-3'>
                    <TextField ref={refCode} label="Kod" fullWidth type={"number"} inputMode="numeric" value={password} onChange={e => setPassword(e.target.value)}></TextField>
                </div>
                <div className='mt-3'>
                    <Button variant='contained' color='primary' fullWidth type='submit'>Logga in</Button>
                </div>
                <div className='mt-3'>
                    <Button variant='outlined' color='primary' fullWidth onClick={back} startIcon={<ArrowBackIosIcon/>}>Tillbaka</Button>
                </div>
            </>
        );
    }

    let loginStep = firstStep ? step1() : step2();

    return (
        <div className="login-wrapper">
            <form onSubmit={handleSubmit}>
                {loginStep}
            </form>
            <Box display={progress} className='centered'>
                <CircularProgress />
            </Box>
            <Snackbar open={errorOpen} autoHideDuration={4000} onClose={handleErrorClose}>
                <MuiAlert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                {errors.map((error, i) => (
                    <div key={i}>{error}</div>
                ))}
                </MuiAlert>
            </Snackbar>
        </div>
        
    )   
}