import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmDialog = (props) => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const handleConfirm = (confirmed) => {
        props.onConfirm(confirmed);
    };

    return (
        <div>
        <Dialog
            open={open}
            onClose={() => handleConfirm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {props.title}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.children}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleConfirm(false)}>Nej</Button>
            <Button onClick={() => handleConfirm(true)} autoFocus>
                Ja
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}