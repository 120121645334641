import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Typography } from '@mui/material';
import { CircularProgress, Box } from '@mui/material';
import axios from 'axios';

export const Debuglogin = (props) => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);
    const [progress, setProgress] = useState('none');
    const [errors, setErrors] = useState([]);
    const history = useHistory();


    async function loginUser(credentials) {
        await axios.post("api/security/authenticate", credentials)
            .then(res => {
                props.setProfile(res.data);
                history.push("/");
            })
            .catch(error => {
                setErrors([error.response.data.message]);
                setErrorOpen(true);
            });
    }

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    const handleSubmit = async e => {
        setProgress('display');
        e.preventDefault();
        await loginUser({
            userName,
            password
        });
        setProgress('none');
    }

    function step1() {
        return (
            <>
                <div className='mt-5'>
                    <Typography>Skriv in din e-postadress så kommer en engångskod att skickas till dig via SMS.</Typography>
                </div>
                <div className='mt-3'>
                    <TextField label="E-post" type="email" fullWidth value={userName} onChange={e => setUserName(e.target.value)}></TextField>
                </div>
                <div className='mt-3'>
                    <TextField label="Kod" fullWidth type={"password"} value={password} onChange={e => setPassword(e.target.value)}></TextField>
                </div>
                <div className='mt-3'>
                    <Button variant='contained' color='primary' fullWidth type='submit'>Logga in</Button>
                </div>
            </>
        );
    }

    let loginStep = step1();

    return (
        <div className="login-wrapper">
            <form onSubmit={handleSubmit}>
                {loginStep}
            </form>
            <Box display={progress} className='centered'>
                <CircularProgress />
            </Box>
            <Snackbar open={errorOpen} autoHideDuration={4000} onClose={handleErrorClose}>
                <MuiAlert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                {errors.map((error, i) => (
                    <div key={i}>{error}</div>
                ))}
                </MuiAlert>
            </Snackbar>
        </div>
        
    )   
}