
import React, {useEffect, useState} from 'react';
import {ElapsedMinutes} from '../../helpers/Utils';
import Fab from '@mui/material/Fab';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const FabTimer = (props) => {
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timer, setTimer] = useState();
    const [counterText, setCounterText] = useState("");

    useEffect(() => {
        setTimer(props.timer);
        updateTimer(props.timer);
        const interval = setInterval(() => {
            updateTimer(props.timer);
        }, 3000);
        return () => clearInterval(interval);
    }, [props]);

    function updateTimer(t) {
        if(t) {
            let elapsedMinutes = ElapsedMinutes(t);
            let hour = Math.round((elapsedMinutes-1)/60);
            let min = elapsedMinutes % 60;
            setCounterText(`${hour}:${pad(min,2)}`);
            setTimerMinutes(elapsedMinutes);
        }
    }

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    const handleClick = (event) => {
        let hour = Math.round((timerMinutes-1)/60);
        let min = ((timerMinutes/60)-hour)>0?.5:0;

        props.onClick(event,{
            id: 0,
            date: timer.date,
            projectId: timer.projectId,
            taskId: timer.taskId,
            debWork: hour+min,
            text: timer.text,
            isTimer: true
        });
    }

    const fabStyleTimer = {
        position: 'fixed',
        bottom: 30,
        left: 20,
    };

    return (
        <>
        {timer &&
            <Fab 
                sx={fabStyleTimer} 
                aria-label="Lägg till" 
                color={'primary'}
                aria-controls={undefined}
                aria-haspopup="true"
                aria-expanded={undefined}
                onClick={handleClick}
                variant="extended"
            ><AccessTimeIcon/> {counterText}</Fab>
        }
        </>
    )
}