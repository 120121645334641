import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export const MinutePicker = (props) => {
    const [tmpValue, setTmpValue] = useState("0min");
    const [value, setValue] = useState(0);

    useEffect(() => {
        let val = props.value;
        setControls(val);
    }, [props]);

    const setControls = (val) => {
        setValue(val);
        setTmpValue(`${val===0 ? 0 : 30 }`);
    }

    const handleClick = () => {
        let val = value===.5 ? 0 : .5;
        setControls(val);
        props.onChange(val);
    }

    return (
        <>
            <ArrowCircleLeftIcon sx={{fontSize: "3rem"}} onClick={() => handleClick()} />
            <TextField size='small' 
                label="min"
                disabled
                inputProps={{
                    style: {fontSize: 20}
                }} 
                sx={{width: "50px"}} value={tmpValue}></TextField>
            <ArrowCircleRightIcon sx={{fontSize: "3rem"}} onClick={() => handleClick()} />
        </>
    );
}