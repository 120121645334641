import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import Select from 'react-select'
import { MinutePicker } from './controls/MinutePicker';
import { HourPicker } from './controls/HourPicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from './controls/ConfirmDialog';
import UseProfile from './controls/UseProfile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const Timeregister = (props) => {
    const { profile } = UseProfile();
    const [loading, setLoading] = useState(true);
    const [currentReg, setCurrentReg] = useState(null);
    const [timerText, setTimerText] = useState("Starta timer");
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [hour, setHour] = useState(1);
    const [minute, setMinute] = useState(0);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const descRef = useRef(null);
    
    useEffect(() => {
        setCurrentReg(props.currentReg);
        setHour(Math.trunc(props.currentReg.debWork));
        setMinute(props.currentReg.debWork - Math.trunc(props.currentReg.debWork));
        if(props.currentReg.isTimer){
            setTimerText("Ångra timer");
        }
        if(props.projects) {
            setProjects(props.projects);
            setLoading(false);
        }
        if(tasks.length == 0) {
            getTasks(props.currentReg?.projectId);
        }
    }, [props]);

    const handleHourChange = (val) => {
        setHour(val);
        setCurrentReg({
            ...currentReg,
            debWork: parseInt(val) + parseFloat(minute)
        })
    };

    const handleMinuteChange = (val) => {
        setMinute(val);
        setCurrentReg({
            ...currentReg,
            debWork: parseFloat(val) + parseInt(hour)
        })
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    const onDeleteConfirm = (confirmed) => {
        setOpenDeleteConfirm(false);
        if(confirmed) {
            axios.delete(`api/timereg/${currentReg.id}`, { headers: { "Authorization": `Bearer ${profile.token}` } })
                .then(res => {
                    props.onSave({error: false, msg: "Registreringen raderad"});
                })
                .catch(error => {
                    props.onSave({error: true, msg: "Det uppstod ett problem när registreringen skulle raderas"});
                });
        }
    };

    const saveTimer = () => {
        if(currentReg.isTimer) {
            deleteTimer();
        }
        else {
            axios.post("api/timereg/timer", currentReg, { headers: { "Authorization": `Bearer ${profile.token}` } })
                .then(res => {
                    props.onSave({error: false, msg: "Timer startad"});
                })
                .catch(error => {
                    props.onSave({error: true, msg: "Det uppstod ett problem när timer skulle startas"});
                });
        }
    };

    const deleteTimer = () => {
        axios.delete(`api/timereg/timer/${currentReg.projectId}`, { headers: { "Authorization": `Bearer ${profile.token}` } })
            .then(res => {
                props.onSave({error: false, msg: "Timer avslutad"});
            })
            .catch(error => {
                props.onSave({error: true, msg: "Det uppstod ett problem när timer skulle avslutas"});
            });
    };
    
    const saveRegister = () => {
        let errors = validateInput();
        if(errors.length === 0) {
            if(currentReg.id > 0) {
                axios.put(`api/timereg/${currentReg.id}`, currentReg, { headers: { "Authorization": `Bearer ${profile.token}` } })
                    .then(res => {
                        props.onSave({error: false, msg: "Registreringen uppdaterad"});
                    })
                    .catch(error => {
                        props.onSave({error: true, msg: "Det uppstod ett problem när registreringen skulle sparas"});
                    });
            }
            else {
                axios.post("api/timereg", currentReg, { headers: { "Authorization": `Bearer ${profile.token}` } })
                    .then(res => {
                        props.onSave({error: false, msg: "Registreringen sparad"});
                    })
                    .catch(error => {
                        props.onSave({error: true, msg: "Det uppstod ett problem när registreringen skulle sparas"});
                    });
            }
        }
        else {
            setErrors(errors);
            console.log(errors);
            setErrorOpen(true);
        }
    };

    const onBack = () => {
        props.onBack();
    }

    const validateInput = () => {
        let errors = []
        if(!currentReg.text || currentReg.text?.trim() === "")
            errors.push("Beskrivning måste anges");
        if(currentReg.projectId === 0)
            errors.push("Projekt måste väljas");
        if(currentReg.debWork === 0)
            errors.push("Tid måste anges");
        return errors;
    }

    async function getTasks(projectId) {
        if(projectId) {          
            await axios.get(`api/timereg/project/${projectId}/tasks`, { headers: { "Authorization": `Bearer ${profile.token}` } })
                .then(res => {
                    const taskOptions = res.data.map(p => {
                        return {label: p.subject, value: p.id }
                    });
                    setTasks(taskOptions);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white', fontSize: '12px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          
          return {
            ...styles,
            backgroundColor: '#FFF',
            color: '#000',
            fontSize: '12px',
            cursor: isDisabled ? 'not-allowed' : 'default'
          };
        },
      };

    function renderRegForm() {
        const projOptions = projects ? 
            projects.map(p => {
                return {label: p.friendlyProjectName, value: p.id }
            })
            : '';

        return (
            <>
            <Box
            sx={{
                width: "100%",
                padding: 2,
                paddingBottom: 10,
                color: 'white',
                backgroundColor: 'primary.dark'
            }}
        >
                <Box padding={1}>
                        <Box pb={2} color="black" textAlign={"center"}>
                            <Button size="small" startIcon={<CalendarTodayIcon />} onClick={() => setCalendarIsOpen(!calendarIsOpen)}>
                                <DatePicker dateFormat="eeee d MMM" selected={Date.parse(currentReg.date)} open={calendarIsOpen} className="datePicker"   onChange={(dte) => setCurrentReg({...currentReg, date: dte })} />
                            </Button>
                        </Box>
                        <Box pb={2}>
                            <Select 
                                value={projOptions?.filter(option => option.value === currentReg?.projectId)} 
                                onChange={(event) => {
                                    setCurrentReg({ ...currentReg, projectId: event.value });
                                    getTasks(event.value);
                                }} 
                                options={projOptions} 
                                styles={colourStyles}
                                />
                        </Box>
                        {tasks.length > 0 &&
                            <Box pb={2}>
                                <Select 
                                    id="task-select"
                                    placeholder='Välj uppgift'
                                    value={tasks?.filter(option => option.value === currentReg.taskId)} 
                                    onChange={(event) => {
                                        setCurrentReg({ ...currentReg, taskId: event.value})
                                    }}
                                    styles={colourStyles}
                                    options={tasks} 
                                    label="Uppgift"
                                    />
                            </Box>
                        }
                        <Box pb={6} pt={5} textAlign="center">
                            <div className='row'>
                                <div className='col text-right p-0'>
                                    <HourPicker value={hour} onChange={handleHourChange} />
                                </div>
                                <div className='col text-left p-0'>
                                    <MinutePicker value={minute} onChange={handleMinuteChange} />
                                </div>
                            </div>
                            
                        </Box>

                        <Box pb={2}>
                            <TextField
                                ref={descRef}
                                id="text-text"
                                label="Beskrivning"
                                multiline={true}
                                fullWidth={true}
                                minRows="3"
                                variant="outlined"
                                value={currentReg?.text}
                                onFocus={() => descRef.current.scrollIntoView()}
                                onChange={(newValue) => setCurrentReg({...currentReg, text: newValue.target.value})}
                            />
                        </Box>
                        <Box pb={2}>
                            <Button variant='contained' fullWidth={true} onClick={saveRegister} startIcon={<SaveIcon/>}>Spara</Button>
                        </Box>
                        {currentReg.id > 0 &&
                            <Box pb={2}>
                                <Button variant="outlined" fullWidth={true} onClick={() => setOpenDeleteConfirm(true)} startIcon={<DeleteIcon/>}>Radera</Button>
                            </Box>
                        }
                        {currentReg.id == 0 &&
                            <Box pb={2}>
                                <Button variant="outlined" fullWidth={true} onClick={saveTimer} startIcon={<AccessTimeIcon/>}>{timerText}</Button>
                            </Box>
                        }
                        <Button variant="outlined" fullWidth={true} color="primary" onClick={onBack} startIcon={<ArrowBackIosIcon/>}>Tillbaka</Button>
                </Box>
                </Box>
                <ConfirmDialog open={openDeleteConfirm} onConfirm={onDeleteConfirm} title="Bekräfta radering">Vill du verkligen radera denna registrering?</ConfirmDialog>
                <Snackbar open={errorOpen} autoHideDuration={4000} onClose={handleErrorClose}>
                    <MuiAlert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                    {errors.map((error, i) => (
                        <div key={i}>- {error}</div>
                    ))}
                    </MuiAlert>
                </Snackbar>
            </>
        )
    }

    let content = loading
        ? <CircularProgress className='centered' />
        : renderRegForm()

    return (
        <>
            {content}
        </>
    );
}