import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Moment from 'moment';

export const FetchCustomers = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    async function getData() {
      const response = await fetch('api/customers')
      .then((res) => res.json())
      .then((json) => {
        setdata(json);
        setloading(false)
      });
    }
    getData();
  }, []);

  function renderData(data) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Created</th>
          <th>Projects</th>
          <th>Tasks</th>
          <th>Tags</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
            <tr key={item.id}>
                <td>{item.id}</td>
                <td><Link to={`/Customer/${item.id}`}>{item.name}</Link></td>
                <td>
                  <div style={{'white-space': 'nowrap'}}>{Moment(item.created).format("YYYY-MM-DD")}</div>
                </td>
                <td>{item.projectsCount}</td>
                <td>{item.tasksCount}</td>
                <td>
                  <Stack direction="row" spacing={1}>
                  {item.tags.map((tag) => (
                    <Chip label={tag.name} />
                  ))}
                  </Stack>
                  </td>
          </tr>
        ))}
      </tbody>
    </table>
    );
  }

  let content = loading
    ? <CircularProgress />
    : renderData(data);

  return (
    <div>
      <h1 id="tabelLabel" >Customers</h1>
      <p>This component demonstrates fetching data from the server.</p>
      {content}
    </div>
  );
};
