import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export const FetchCalendar = () => {
    const [events, setEvents] = useState([]);
    const [loading, setloading] = useState(true);

    useEffect(() => {
      async function getData() {
        const response = await fetch('api/tasks')
        .then((res) => res.json())
        .then((json) => {
          const ev = json.map(result =>
            ({
              start: moment(result.date).toDate(),
              end: moment(result.date)
              .add(1, "hours")
              .toDate(),
              title: result.customerName
            })
            )
            setEvents(ev);
            setloading(false)
        });
      }
      getData();
  }, []);

  function renderData(data) {
    return (
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
        events={data}
      />
    )
  }

  let content = loading
    ? <CircularProgress />
    : renderData(events)

  return(
    <div className="App">
        {content}
    </div>
  );
}
