import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UseProfile from './controls/UseProfile';

export const Logout = () => {
    const { setProfile } = UseProfile();
    const history = useHistory();

    useEffect(() => {
        setProfile(null);
        history.push("/login");
    });

    return(
        <></>
    )
}