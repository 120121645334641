import React, {  } from 'react';
import { Container } from 'reactstrap';
import { Topbar } from './Topbar';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      //main: '#eb5545',
      main: '#fc574c',
      dark: '#121212'
    },
    secondary: {
      main: grey[300]
    }
  },
  typography: {
    fontSize: 14,
    fontWeightRegular: 400
  }
});

export const Layout = (props) => {
  const onLogout = () => {
    if(props.onLogout)
      props.onLogout();
  }

    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Topbar onLogout={onLogout} />
        <Container>
          <div className='p-2'>
            {props.children}
          </div>
        </Container>
      </ThemeProvider>
    )
}
