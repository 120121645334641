import * as React from 'react';
import { Box } from '@mui/material';

export default function NoResult(props) {

    return (
        <Box
            width={"100%"}
            height={"350px"}
            justifyContent='center'
            sx={{ display: 'flex', alignItems: 'center' }}
        >
            {props.children}
        </Box>
    );
  }