import React, {useEffect, useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Avatar, List, ListItem, ListItemAvatar, ListItemText, TextField, IconButton, ListItemSecondaryAction } from '@mui/material';
import { Search, Phone, Email } from '@mui/icons-material';
import UseProfile from './controls/UseProfile';
import NoResult from './controls/NoResult';
import axios from 'axios';

export const Contacts = () => {
    const history = useHistory();
    const params = useParams();
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const { profile, setProfile } = UseProfile();
    const [filter, setFilter] = useState('');

    useEffect(() => {
        getContacts();
    }, [params]);

    async function getContacts() {
        await axios.get(`api/employees`, { headers: { "Authorization": `Bearer ${profile.token}` } })
            .then(res => {
                setContacts(res.data);
                setFilteredContacts(res.data);
            })
            .catch(error => {
            });
    }

    const filterLists = (txt) => {
        txt = txt.toLowerCase();
        setFilter(txt);
        setFilteredContacts(contacts.filter(f => f.firstName.toLowerCase().includes(txt) || f.lastName.toLowerCase().includes(txt)));
    }

    const phoneButton = (number) => {
        if(number && number.length > 0) {
            console.log(number);
            return (
                <IconButton onClick={() => window.location.href="tel:" + number}>
                    <Phone />
                </IconButton>
            )
        }
        return;
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    color: 'white',
                    backgroundColor: 'primary.dark'
                }}
            >
                <div>
                    <TextField
                        id="filter-text"
                        fullWidth={true}
                        value={filter}
                        size="small"
                        onChange={(newValue) => filterLists(newValue.target.value)}
                        InputProps={{
                            startAdornment: <Search />
                        }}
                    />
                </div>
                {filteredContacts.length > 0 &&
                    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {filteredContacts.map((c) => {
                            return (
                                <ListItem
                                    key={c.id}
                                    sx={{paddingRight: 0, paddingLeft: 0}}
                                >
                                    <ListItemAvatar>
                                        <Avatar />
                                    </ListItemAvatar>
                                    <ListItemText primary={c.firstName + " " + c.lastName} />
                                    <ListItemSecondaryAction
                                        sx={{right:0}}
                                    >
                                    <List>
                                        <ListItem
                                            sx={{paddingRight: 0}}
                                        >
                                            {phoneButton(c.mobile)}
                                            <IconButton onClick={() => window.location.href="mailto:" + c.email}><Email /></IconButton>
                                        </ListItem>
                                    </List>
                                </ListItemSecondaryAction>
                            </ListItem>
                            );
                        })}
                    </List>
                }
                {filteredContacts.length === 0 &&
                    <NoResult>
                        Inga kontakter hittades...
                    </NoResult>
                }
            </Box>
        </>
    );
}