import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UseProfile from './controls/UseProfile';
import { CircularProgress, Box, Typography, Drawer } from '@mui/material';
import Moment from 'moment';
import { CampaignDetails } from './CampaignDetails';
import axios from 'axios';

export const Campaigns = (props) => {
    const { profile } = UseProfile();
    const params = useParams();
    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState();
    const [loading, setloading] = useState(true);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        isPaneOpen: false,
        isRegPaneOpen: false
    });

    useEffect(() => {
        props.checkAuth();
        getCampaigns();
    }, [params]);

    async function getCampaigns() {
        await axios.get(`api/campaigns`, { headers: { "Authorization": `Bearer ${profile.token}` } })
            .then(res => {
                setCampaigns(res.data);
                setloading(false);
            })
            .catch(error => {
            });
    }

    async function getCampaign(id, anchor, open) {
        await axios.get(`api/campaigns/${id}`, { headers: { "Authorization": `Bearer ${profile.token}` } })
            .then(res => {
                setCampaign(res.data);
                setloading(false);
                setState({ ...state, [anchor]: open });
            })
            .catch(error => {
            });
    }

    const onBack = () => {
        props.checkAuth();
        setState({ ...state, ["right"]: false });
    }

    const toggleDrawer = (anchor, open, reg) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.checkAuth();
        setloading(true);
        getCampaign(reg.id, anchor, open);
    };

    function renderCampaigns() {
        return (
            <>
                <Box
                    sx={{
                        width: "100%",
                        padding: 1,
                        paddingBottom: 0,
                        marginTop: 1,
                        color: 'white',
                        backgroundColor: 'primary.dark',

                    }}
                >
                    <Typography color={"secondary"} fontWeight="bold">Minibullar</Typography>
                </Box>
                <table className='table'>
                    <tbody>
                    {campaigns.map((reg) => (
                        <tr key={reg.id} className="border-dark" onClick={toggleDrawer("right", true, reg)}>
                            <td>
                                <div className='d-inline-block text-truncate'>
                                    <Typography color={"primary"} fontSize="1rem">{reg.settings.subject_line}</Typography>
                                    <Typography color={"secondary"} fontSize=".8rem">{reg.settings.preview_text}</Typography>
                                    <Typography color={"#999"} fontSize=".8rem">{Moment(reg.send_time).format("YYYY-MM-DD HH:mm")}</Typography>
                                </div>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
                <Drawer
                    anchor={"right"}
                    open={state["right"]}
                    PaperProps={{
                        sx: { width: "100%" },
                      }}
                    onClose={toggleDrawer("right", false)}
                    >
                    <CampaignDetails campaign={campaign} onBack={onBack} />
                </Drawer>
            </>
        )
    }

    let content = loading
        ? <CircularProgress className='centered' />
        : renderCampaigns()

    return (
        <>
        {content}
        </>
    )
}