import React, { useEffect, useState } from 'react';
import { Timeregister } from './Timeregister';
import "react-sliding-pane/dist/react-sliding-pane.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Drawer, Typography, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SelectableTaskList = (props) => {
    const [date, setDate] = useState();
    const [projectLabel, setProjectLabel] = useState('Mina projekt');
    const [taskLabel, setTaskLabel] = useState('Mina uppgifter');
    const [filter, setFilter] = useState('');
    const [currentReg, setCurrentReg] = useState(null);
    const [timer, setTimer] = useState(null);
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [state, setState] = useState({
        bottom: false
    });

    useEffect(() => {
        setTimer(props.timer);
        setProjects(props.projects);
        setDate(props.date);
        setTasks(props.tasks);
        setProjectList(props.projects.filter(p => p.assignedMe));
        setTaskList(props.tasks.filter(p => p.assignedMe));
    }, [props]);


    const selectTask = (task) => {
        setCurrentReg({
            id: 0,
            date: date,
            projectId: task.projectId,
            taskId: task.id,
            debWork: 1,
            text: ''
            }
        );
    }

    const selectProject = (proj) => {
        setCurrentReg({
            id: 0,
            date: date,
            projectId: proj.id,
            taskId: 0,
            debWork: 1,
            text: ''
            }
        );
    }

    const onSave = (res) => {
        setState({ ...state, "bottom": res.error });
        props.onSave(res);
    }

    const onBackTimereg = () => {
        setState({ ...state, "bottom": false });
    }

    const onBack = () => {
        props.onBack();
    }

    const filterLists = (txt) => {
        txt = txt.toLowerCase();
        setFilter(txt);
        if(txt.length > 2) {
            setTaskLabel("Hittade uppgifter");
            setProjectLabel("Hittade projekt");
            setTaskList(tasks.filter(t => t.friendlyProjectName.toLowerCase().includes(txt) || t.subject.toLowerCase().includes(txt)))
            setProjectList(projects.filter(p => p.friendlyProjectName.toLowerCase().includes(txt)))
        }
        else {
            setTaskLabel("Mina uppgifter");
            setProjectLabel("Mina projekt");
            setTaskList(tasks.filter(p => p.assignedMe));
            setProjectList(projects.filter(p => p.assignedMe));
        }
    }


    const toggleDrawer = (anchor, open, task, proj) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(task) selectTask(task);
        if(proj) selectProject(proj);
        setState({ ...state, [anchor]: open });
    };

    return (
        <>
        <Box
            sx={{
                width: "100%",
                padding: 2,
                color: 'white',
                backgroundColor: 'primary.dark'
            }}
        >
                <Box
                    sx={{
                        width: "100%",
                        padding: 1,
                        color: 'white',
                        backgroundColor: 'primary.dark'
                    }}
                >
                    <div className="row mt-1 mb-1">
                        <div className="col text-left" style={{display: "flex", alignItems: "center"}}>
                            <ArrowBackIosIcon fontSize='medium' color="white" onClick={onBack}/>
                        </div>
                        <div className="col-10 text-center" style={{display: "flex", alignItems: "center", textAlign: "center"}}>
                            <Typography fontSize="1.2rem">Välj uppgift eller projekt</Typography>
                        </div>
                    </div>
                </Box>
                <div className='mt-2 p-2'>
                    <TextField
                        id="filter-text"
                        fullWidth={true}
                        value={filter}
                        size="small"
                        onChange={(newValue) => filterLists(newValue.target.value)}
                        InputProps={{
                            startAdornment: <SearchIcon />
                        }}
                    />
                </div>
                {taskList.length > 0 &&
                    <>
                        <Box
                            sx={{
                                width: "100%",
                                padding: 1,
                                paddingBottom: 0,
                                marginTop: 1,
                                color: 'white',
                                backgroundColor: 'primary.dark',
        
                            }}
                        >
                            <Typography color={"secondary"} fontWeight="bold">{taskLabel}</Typography>
                        </Box>
                        <table className='table'>
                            <tbody>
                            {taskList.map((task) => (
                                <tr key={task.id} className="border-dark" onClick={toggleDrawer("bottom", true, task)}>
                                    <td>
                                        <div className='d-inline-block text-truncate' style={{width: "250px"}}>
                                            <Typography color={"primary"} fontSize="1rem">{task.subject}</Typography>
                                            <Typography color={"secondary"} fontSize=".8rem">{task.friendlyProjectName}</Typography>
                                        </div>
                                    </td>
                                    <td><Typography color={"secondary"} fontSize="1rem" textAlign="right">{Math.round(task.processedTime)}/{task.requestedTime} h</Typography></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                }

                {projectList.length > 0 &&
                    <>
                        <Box
                            sx={{
                                width: "100%",
                                padding: 1,
                                paddingBottom: 0,
                                marginTop: 3,
                                color: 'white',
                                backgroundColor: 'primary.dark',
                            }}
                        >
                            <Typography color={"secondary"} fontWeight="bold">{projectLabel}</Typography>
                        </Box>
                        <table className='table' width={"100%"}>
                            <tbody>
                            {projectList.map((proj) => (
                                <tr key={proj.id} className="border-dark" onClick={toggleDrawer("bottom", true, null, proj)}>
                                    <td className='overflow-hidden'>
                                        <div className='d-inline-block overflow-hidden' sx={{width: "100%"}}>
                                            <Typography color={"primary"} fontSize="1rem">{proj.friendlyProjectName}</Typography>
                                            <Typography color={"secondary"} fontSize=".8rem">Typ: Löpande, budget: 12333 </Typography>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                }    

                {projectList.length === 0 && taskList.length === 0 &&
                    <>
                        <div>Inga projekt eller uppgifter hittades...</div>
                    </>
                }
            </Box>
            
            <Drawer 
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer("bottom", false)}
                sx={{backgroundColor:"#212529"}}
                >
                <Timeregister projects={projects} currentReg={currentReg} timer={timer} onSave={onSave} onBack={onBackTimereg} />
            </Drawer>
        </>
    )
}